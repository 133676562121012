import { useSidebarStore } from '~/src/widgets/terminal/store';
import type { TraderDrawers } from '~/src/widgets/terminal/enum';

export const useDrawer = () => {
	const sidebarStore = useSidebarStore();

	const openDrawer = (drawerName: keyof typeof TraderDrawers) => {
		sidebarStore.toggleDrawer(drawerName, true);
	};

	return {
		openDrawer,
	};
};
